import React from 'react';
import { Wrapper, Title } from './styled';

type AlertProps = {
  variant?: 'primary';
  title?: React.ReactNode;
  content?: React.ReactNode;
  children?: React.ReactNode;
};

function Alert({ variant = 'primary', title, content, children, ...props }: AlertProps) {
  return (
    <Wrapper variant={variant} {...props}>
      {title && <Title>{title}</Title>}
      {content}
      {children}
    </Wrapper>
  );
}

export { Alert, type AlertProps };
