import React from "react";
import Icon from "@/shared/ui/Icon";
import { XMarkIcon } from "@/shared/ui/Icon/svg";
import { Title, Content, Blur, Wrapper, FullscreenWrapper } from "./styled";

export interface ModalProps
  extends Omit<React.DetailedHTMLProps<React.HTMLAttributes<HTMLDialogElement>, HTMLDialogElement>, "title"> {
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  content: React.ReactNode;
  onClose: () => void;
  isOpen: boolean;
}

const Modal = React.forwardRef<any, ModalProps>(({ title, subtitle, content, onClose, isOpen, ...props }, ref) =>
  (
    <FullscreenWrapper $isOpen={isOpen}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <Wrapper ref={ref as any} {...props} open={isOpen}>
        <div>
          <Blur />
          <Content>
            <Title>
              <div>
                <div>{title}</div>
                {subtitle && <div>{subtitle}</div>}
              </div>
              <button type="button" onClick={onClose}>
                <Icon width={14} height={14} component={XMarkIcon} />
              </button>
            </Title>
            {content}
          </Content>
        </div>
      </Wrapper>
    </FullscreenWrapper>
  )
);

Modal.displayName = "Modal";

export { Modal };
