import React from 'react';
import { CheckIcon } from '@/shared/ui/Icon/svg';
import Icon from '@/shared/ui/Icon';
import { Wrapper } from './styled';

type Props = {
  checked: boolean;
  id?: string;
  color?: string;
  type?: 'checkbox' | 'radio';
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

function Checkbox({ checked, onChange, id, color = '#2253F5', type = 'checkbox' }: Props) {
  return (
    <Wrapper checked={checked} $color={color}>
      {checked && <Icon width={12} height={12} component={CheckIcon} />}
      <input id={id} name={id} type={type} onChange={onChange} />
    </Wrapper>
  );
}

export { Checkbox };
