'use client';

import React, { useEffect } from 'react';
import { useCookieConsent } from '@/context/CookieConsentContext';
import { loadGtagScript, loadMixpanel, loadFacebookScript } from '@/utils/analyticScripts';
import {
  delay,
  sendSafeGtagEvent,
  sendSafeMixpanelEvent,
  sendSafeFbqEvent,
} from '@/utils/common';

enum AnalyticsServices {
  Google = 'gtag',
  Mixpanel = 'mixpanel',
  Meta = 'fbq',
}

type AnalyticsContextProps = {
  sendSafeMixpanelEvent: typeof sendSafeMixpanelEvent;
  sendSafeGtagEvent: typeof sendSafeGtagEvent;
  sendSafeFbqEvent: typeof sendSafeFbqEvent;
};

const AnalyticsContext = React.createContext<AnalyticsContextProps>({
  sendSafeMixpanelEvent,
  sendSafeGtagEvent,
  sendSafeFbqEvent,
});

function AnalyticsContextProvider<T extends {}>(props: T) {
  const cookieConsent = useCookieConsent();
  const [unsentEventsTuple, setUnsentEventsTuple] = React.useState<{ [k in AnalyticsServices]?: unknown[][] } | null>(
    null
  );
  const [isAnalyticsLoaded, setIsAnalyticsLoaded] = React.useState(false);

  useEffect(() => {
    if (
      typeof window !== 'undefined' &&
      process.env.NEXT_PUBLIC_RUNTIME_ENV !== 'development' &&
      cookieConsent.analytics_cookies
    ) {
      const loadAnalyticScripts = async () => {
        loadGtagScript();
        loadMixpanel();
        loadFacebookScript();
        setIsAnalyticsLoaded(true);
        window.removeEventListener('load', loadAnalyticScripts);
      };

      if (document.readyState === 'complete') {
        loadAnalyticScripts();
      } else {
        window.addEventListener('load', loadAnalyticScripts);

        return () => {
          window.removeEventListener('load', loadAnalyticScripts);
        };
      }
    }
  }, [cookieConsent.analytics_cookies]);

  React.useEffect(
    function sendAllUnsentEvents() {
      if (isAnalyticsLoaded && unsentEventsTuple) {
        const handler = async () => {
          await delay(2 * 1000);
          Object.entries(unsentEventsTuple).forEach(([serviceName, eventsList]) => {
            switch (serviceName as unknown as AnalyticsServices) {
              case AnalyticsServices.Mixpanel:
                eventsList.forEach((args) =>
                  sendSafeMixpanelEvent(...(args as Parameters<typeof sendSafeMixpanelEvent<any>>))
                );
                break;
              case AnalyticsServices.Meta:
                eventsList.forEach((args) => sendSafeFbqEvent(...(args as Parameters<typeof sendSafeFbqEvent>)));
                break;
              case AnalyticsServices.Google:
                eventsList.forEach((args) => sendSafeGtagEvent(...(args as Parameters<typeof sendSafeGtagEvent>)));
                break;
              default:
                break;
            }
          });

          setUnsentEventsTuple(null);
        };
        handler();
      }
    },
    [isAnalyticsLoaded, unsentEventsTuple]
  );

  function updateUnsentEventsList<A extends unknown[]>(args: A, type: AnalyticsServices) {
    setUnsentEventsTuple((prev) => ({ ...prev, [type]: [...(prev?.[type] ?? []), args] }));
  }

  const analyticsSendingFunctions = React.useMemo(() => {
    if (cookieConsent.analytics_cookies) {
      return {
        sendSafeMixpanelEvent,
        sendSafeGtagEvent,
        sendSafeFbqEvent,
      };
    }

    return {
      sendSafeMixpanelEvent: (...args: Parameters<typeof sendSafeMixpanelEvent<any>>) =>
        updateUnsentEventsList(args, AnalyticsServices.Mixpanel),
      sendSafeGtagEvent: (...args: Parameters<typeof sendSafeGtagEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Google),
      sendSafeFbqEvent: (...args: Parameters<typeof sendSafeFbqEvent>) =>
        updateUnsentEventsList(args, AnalyticsServices.Meta),
    };
  }, [cookieConsent.analytics_cookies]);

  return <AnalyticsContext.Provider value={analyticsSendingFunctions} {...props} />;
}

function useAnalyticsContext() {
  return React.useContext(AnalyticsContext);
}

export { AnalyticsContextProvider, AnalyticsContext, useAnalyticsContext };
