import React from 'react';
import Link from 'next/link';
import { useLocale, useTranslations } from 'next-intl';
import { useWindowSize } from '@/context/WindowSizeContext';
import { CookiesNames } from '@/shared/constants';
import Button from '@/shared/ui/Button';
import Modal from '@/shared/ui/Modal';
import { useModalControls } from '@/shared/hooks';
import Checkbox from '@/shared/ui/Checkbox';
import { getCookie, setCookie, getEsimplusLink } from '@/shared/lib';
import {
  ButtonsWrapper,
  CustomizeSettingsButton,
  GDPRContent,
  PrivacySettingsItem,
  PrivacySettingsItemContent,
  PrivacySettingsItemDescription,
  PrivacySettingsItemsList,
  PrivacySettingsItemTitle,
  PrivacySettingsModalContent,
  Wrapper,
} from './styled';

function GDPR<T extends { [k: string]: boolean }>({
  onSubmit = () => {},
  initialSettings,
}: {
  onSubmit?: (props: T) => void;
  initialSettings: T;
}) {
  const t = useTranslations();
  const locale = useLocale();
  const [isOpen, setIsOpen] = React.useState(false);
  const [privacySettings, setPrivacySettings] = React.useState<T>(initialSettings);
  const { isMobile } = useWindowSize();

  const { isOpen: isSettingsModalOpen, openModal, closeModal } = useModalControls(false, { disableBodyScroll: true });

  React.useEffect(() => {
    if (isMobile) {
      const hcIframe = Array.from(document.getElementsByClassName('helpcrunch-iframe-wrapper'))[0] as
        | Element
        | undefined;

      if (hcIframe && typeof window !== 'undefined') {
        if (isOpen) {
          hcIframe.classList.add('hidden');
        } else {
          hcIframe.classList.remove('hidden');
        }
      }
    }
  }, [isOpen, isMobile]);

  React.useEffect(() => {
    setIsOpen(!getCookie(CookiesNames.Consent));
  }, []);

  const updatePrivacySettings = (config: Partial<T>) => {
    setPrivacySettings((prev) => ({ ...prev, ...config }));
  };

  const handleCookieConsent = (config: T) => {
    setCookie(
      CookiesNames.Consent,
      new URLSearchParams(Object.entries(config).map(([key, val]) => [key, String(Number(val))])).toString(),
      30
    );
    setIsOpen(false);
    onSubmit(config);
  };

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        title={t('cookie_consent.settings_modal_title')}
        content={
          <PrivacySettingsModalContent>
            <PrivacySettingsItemsList>
              {Object.keys(privacySettings).map((key) => (
                <PrivacySettingsItem key={key}>
                  <Checkbox
                    checked={privacySettings[key]}
                    onChange={() => updatePrivacySettings({ [key]: !privacySettings[key] } as Partial<T>)}
                    id={key}
                  />
                  <PrivacySettingsItemContent>
                    <PrivacySettingsItemTitle>{t(`cookie_consent.${key}.title`)}</PrivacySettingsItemTitle>
                    <PrivacySettingsItemDescription>
                      {t(`cookie_consent.${key}.description`)}
                    </PrivacySettingsItemDescription>
                  </PrivacySettingsItemContent>
                </PrivacySettingsItem>
              ))}
            </PrivacySettingsItemsList>
            <Button fullWidth label={t('cookie_consent.save')} size="small" onClick={closeModal} />
          </PrivacySettingsModalContent>
        }
        isOpen={isSettingsModalOpen}
        onClose={closeModal}
      />
      {!isSettingsModalOpen && (
        <Wrapper>
          <GDPRContent>
            <h5>{t('cookie_consent.title')}</h5>
            <p>
              {t.rich('cookie_consent.text', {
                a: (chunk) => <Link href={getEsimplusLink(locale, '/privacy')}>{chunk}</Link>,
              })}
            </p>
            <div>
              <CustomizeSettingsButton onClick={openModal}>
                {t('cookie_consent.customize_settings')}
              </CustomizeSettingsButton>
              <ButtonsWrapper>
                <Button
                  label={t('cookie_consent.accept_all')}
                  size="small"
                  variant="secondary"
                  onClick={() => {
                    handleCookieConsent(privacySettings);
                  }}
                />
              </ButtonsWrapper>
            </div>
          </GDPRContent>
        </Wrapper>
      )}
    </>
  );
}

export { GDPR };
