import styled, { css } from 'styled-components';

export const Title = styled.div`
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: -0.2px;
  color: ${(props) => props.theme.primaryText};
  margin-bottom: 15px;
`;

export const Wrapper = styled.div<{ variant: 'primary' }>`
  padding: 25px;
  border-radius: 10px;
  width: 100%;

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 5px;

    li {
      padding-left: 20px;
      position: relative;

      &:before {
        content: '';
        display: block;
        width: 4px;
        height: 4px;
        border-radius: 10px;
        background: ${(props) => props.theme.primaryText};
        position: absolute;
        left: 8px;
        top: 8px;
      }
    }
  }

  ${(props) => {
    switch (props.variant) {
      case 'primary':
        return css`
          background: ${props.theme.alertPrimaryBg};
        `;
      default:
        return css``;
    }
  }}
`;
