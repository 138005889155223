import styled from 'styled-components';
import Alert from '@/shared/ui/Alert';

export const PrivacySettingsItemDescription = styled.div``;
export const PrivacySettingsItemContent = styled.div`
  font-size: 14px;
  line-height: 14px;
  color: ${(props) => props.theme.secondaryText};
`;
export const PrivacySettingsItemTitle = styled.div`
  font-size: 16px;
  line-height: 18px;
  font-weight: bold;
  margin-bottom: 5px;
  color: ${(props) => props.theme.primaryText};
`;
export const PrivacySettingsItem = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
export const PrivacySettingsModalContent = styled.div`
  padding: 16px;

  button {
    margin-top: 20px;
    font-weight: bold;
  }
`;
export const PrivacySettingsItemsList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const CustomizeSettingsButton = styled.div`
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  margin: 5px 0;
`;

export const GDPRContent = styled.div`
  font-size: 12px;
  color: ${(props) => props.theme.secondaryText};

  h5 {
    color: ${(props) => props.theme.primaryText};
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 15px 0;
    padding: 0;
  }

  p {
    margin: 5px 0;
    padding: 0;

    a {
      font-size: inherit;
      text-decoration: underline;
    }
  }

  > div:last-of-type {
    margin: 15px 0 0 0;
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 450px) {
    > div:last-of-type {
      flex-direction: column;
      align-items: flex-start;

      > ${ButtonsWrapper} {
        width: 100%;

        button {
          width: 100%;
        }
      }
    }
  }
`;

export const Wrapper = styled(Alert)`
  background: ${(props) => props.theme.cardsBg};
  border: 2px solid ${(props) => props.theme.borderColor};
  position: fixed;
  z-index: 999999999;
  bottom: 16px;
  left: 16px;
  padding: 10px;
  border-radius: 10px;
  width: calc(100% - 32px);
  max-width: 500px;
`;
